




































































































































































































import useAttachment from '@/use/attachment'
import useService from '@/use/service'
import { defineComponent, onMounted, reactive, ref } from '@vue/composition-api'
import { AxiosInstance } from 'node_modules/axios'

export default defineComponent({
  setup(_, { root }) {
    const { formatService } = useService()
    const { uploadAttachments, downloadAttachment, deleteAttachment, truncateFileName } = useAttachment({ root })

    const state = reactive({
      loading: false,
      error: null as null | string
    })

    const service = ref<{ [key: string]: any } | null>(null)

    const fetch = () => {
      const $axios = root.$store.getters['api/getInstance'] as AxiosInstance

      $axios
        .get(`service/${root.$route.params.id}`)
        .then(({ data }) => {
          service.value = data.service
        })
        .catch(error => {
          if (error.response?.status) {
            switch (error.response.status) {
              case 404: state.error = 'Nie znaleziono serwisu'
                break
              case 403: state.error = 'Nie posiadasz uprawnień do wyświetlenia zasobu'
                break
              default: state.error = 'Wystąpił błąd'
            }
          }
        })
    }

    onMounted(fetch)

    const uploadToExisting = async (id: string, files: File[]) => {
      const $axios = root.$store.getters['api/getInstance'] as AxiosInstance

      uploadAttachments(files)
        .then(({ data }) => {
          $axios
            .post(`service/${id}/attachment`, { attachment: data.attachments.map((a: { id: string }) => a.id) })
            .then(fetch)
            .catch(console.log)
        })
    }

    const handleFileInput = (id: string, event: Event) => {
      event.preventDefault()

      let files: File[] = []

      const input = event.target as HTMLInputElement
      if (input.files) {
        files = []
        for (let i = 0; i < input.files.length; i++) {
          const file = input.files[i]
          if (file) files.push(file)
        }
      }

      if (files.length > 0) uploadToExisting(id, files)

      input.value = ''
      if (input.value) {
        input.type = 'text'
        input.type = 'file'
      }
    }

    const handleDrag = (id: string, event: Event, type: 'enter' | 'leave') => {
      event.preventDefault()

      if (type === 'leave') (event.target as HTMLElement)?.classList.remove('hover')
      else (event.target as HTMLElement)?.classList.add('hover')
    }

    const handleDrop = (id: string, event: DragEvent) => {
      event.preventDefault()

      if (event.target) (event.target as HTMLElement)?.classList.remove('hover')

      let files: File[] = []

      if (event.dataTransfer?.items) {
        // Use DataTransferItemList interface to access the file(s)
        files = []
        for (let i = 0; i < event.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          if (event.dataTransfer.items[i].kind === 'file') {
            const file = event.dataTransfer.items[i].getAsFile()
            if (file) files.push(file)
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        files = []
        const filesLength = event.dataTransfer?.files.length || 0
        for (let i = 0; i < filesLength; i++) {
          const file = event.dataTransfer?.files[i] as File
          if (file) files.push(file)
        }
      }

      if (files.length > 0) uploadToExisting(id, files)
    }


    return {
      formatService,
      downloadAttachment,
      deleteAttachment,
      truncateFileName,

      handleFileInput,
      handleDrag,
      handleDrop,

      state,
      service,
      fetch
    }
  },
})
